var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var hover = _ref.hover;
          return [_c('v-card', {
            staticClass: "fill-height",
            class: {
              'item--hovered': hover
            },
            attrs: {
              "outlined": "",
              "tile": "",
              "to": `${_vm.$route.path}/${board._id}`
            }
          }, [_c('v-img', {
            attrs: {
              "src": board.thumb,
              "aspect-ratio": 1 / 1
            }
          }), _c('v-card-title', [_vm._v(" " + _vm._s(board.subject) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")])], 1)];
        }
      }], null, true)
    })], 1);
  }), 1), !_vm.hidePagination ? _vm._t("pagination") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }