<template>
    <v-sheet>
        <v-row>
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4" lg="3">
                <v-hover v-slot="{ hover }">
                    <v-card outlined tile class="fill-height" :class="{ 'item--hovered': hover }" :to="`${$route.path}/${board._id}`">
                        <v-img :src="board.thumb" :aspect-ratio="1 / 1" />
                        <v-card-title> {{ board.subject }} </v-card-title>
                        <v-card-text> {{ board.createdAt.toDate() }} </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <slot v-if="!hidePagination" name="pagination"></slot>
    </v-sheet>
</template>

<script>
export default {
    props: {
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },

        hideWriteButton: { type: Boolean, default: false },
        hidePagination: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
.item--hovered::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 3px solid var(--v-secondary-base);
}
</style>